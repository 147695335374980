import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styles from './UnitDetails.module.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner from '../../../images/no-unit-image.svg';
import iconSqfeet from '../../../images/icons/icon-square-feet.svg';
import iconBeds from '../../../images/icons/icon-beds.svg';
import iconBath from '../../../images/icons/icon-bathtub.svg';
import Popup from '../../common/popup/Popup';
import BookAppointment from '../BookAppointment/BookAppointment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import Loader from '../../common/loader/Loader';
import { getUnit, getTime, clearPreviosUnitData } from '../../../redux/unit/actions/unit.action';
import Toast from '../../toast/Toast';
import Lightbox from '../../common/lightbox/Lightbox';
import DollarIcon from '../../../images/icons/icon-Dollar.svg';
import moment from 'moment';
import { SliderWithLightbox } from '../../common/slider-with-lightbox/SliderWithLightBox';
import { getLocationById } from '../../../redux/location/actions/location.action';
import { AppContext } from '../../context/AppContect';
import CamIcon from "../../../images/icons/cam.svg";
import Webcam from 'react-webcam';
import CloseIcon from '../../../images/icon-close.svg';
import CamLayer from '../../../images/cam-layer.svg';
import { uploadImages } from '../../../redux/auth/actions/auth.action';
import { uploadProfilePicture } from '../../../redux/auth/actions/editProfile.action';
import InfoIcon from '../../icons/InfoIcon';

const UnitDetails = () => {
    const locationId = useParams().id;
    const unitId = useParams().unitid;

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const appContext = useContext(AppContext);

    const errorMsg = useSelector((state: IRootState) => state.appointmentReducer.appointment.error?.message);

    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('error');
    const [startIndex, setStartIndex] = useState(0);
    const [showLightBox, setShowLightBox] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appoinmentStartTime, setAppoinmentStartTime] = useState('');
    const [appointmentCloseTime, setAppointmentCloseTime] = useState('');
    const [open, setIsOpen] = useState(false);

    const units = useSelector((state: IRootState) => state.unitReducer.unit.data.units);
    const unitsLoading = useSelector((state: IRootState) => state.unitReducer.unit.loading);
    const loading = useSelector((state: IRootState) => state.unitsByLocationId.loading);
    const unitResponse = useSelector((state: IRootState) => state.unitReducer.unit.data);
    const locationLoading = useSelector((state: IRootState) => state.locationReducer.locationById.loading);
    const locationDetail = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location);
    const id = units?.id;

    useEffect(() => {
        if (unitResponse && Object.keys(unitResponse).length && unitResponse.status === false && unitResponse.statusCode === 404) {
            dispatch(clearPreviosUnitData());
            navigate(`/location/${locationId}`);
        }
    }, [unitResponse]);

    useEffect(() => {
        if (locationId) {
            dispatch(getLocationById(locationId));
        }
    }, [locationId]);

    // const requestCameraPermission = () => {
    //     const askCameraPermission = async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

    //     let localStream: MediaStream | null;
    //     askCameraPermission().then(() => {
    //     }).then(() => {
    //         setHasMediaAccess(true);
    //         localStream?.getTracks().forEach(track => {
    //             track.stop();
    //         });
    //     }).catch((error) => {
    //         if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
    //             setHasMediaAccess(false);
    //             setShowToast(true);
    //             setToastMessage('Camera access denied! Please grant access to your camera to continue.');
    //         }
    //     })
    // };

    useEffect(() => {
        if (search) {
            const [bookedDate, bookedStartTime, bookedCloseTime] = search.replace(/\?/g, '').replace(/&/g, '').split('_');
            const appointmentDate = moment(bookedDate).format('YYYY-MM-DD');
            const appointmentStartTime = moment(bookedStartTime, 'HHmm').format('HH:mm');
            const appoinmentCloseTime = moment(bookedCloseTime, 'HHmm').format('HH:mm');

            setAppointmentDate(appointmentDate);
            setAppoinmentStartTime(appointmentStartTime);
            setAppointmentCloseTime(appoinmentCloseTime);
        } else {
            setAppointmentDate('');
            setAppoinmentStartTime('');
            setAppointmentCloseTime('');
        }
    }, [search]);

    useEffect(() => {
        dispatch(getUnit(unitId));
    }, [dispatch, unitId]);

    useEffect(() => {
        if (showToast) {
            setTimeout(() => {
                setShowToast(false)
                setToastMessage('');
                setToastType('error');
            }, 4000);
        }
    }, [showToast]);

    useEffect(() => {
        if (errorMsg) {
            setShowToast(true);
        }
    }, [errorMsg]);

    useEffect(() => {
        dispatch(getTime(locationId));
        // requestCameraPermission();
    }, []);

    const openModal = () => { setIsOpen(true) };

    const clickForLightboxHandler = (index: number) => {
        setStartIndex(index);
        setShowLightBox(true);
        document.body.classList.add('disable-scrolling');
    };

    const [showWebCam, setShowWebCam] = useState(false);
    const [uploadFiles, setUploadFiles] = useState('');
    const [newProfile, setNewProfile] = useState('');
    // const [hasMediaAccess, setHasMediaAccess] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const webcamRef = useRef<Webcam>(null);

    const videoConstraints: MediaTrackConstraints | boolean = {
        facingMode: 'user', // or 'environment' for rear camera
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            setNewProfile(imageSrc);
        };
    };

    const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
        const trimmedBase64String = base64String.trim();

        if (!trimmedBase64String.startsWith('data:image/jpeg;base64,')) {
            throw new Error('Invalid base64 string format');
        }
        const byteCharacters = atob(trimmedBase64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        return new File([blob], fileName, { type: contentType });
    }

    const onProfileUploadSuccess = (url: string) => {
        setToastMessage('Profile picture updated successfully');
        setToastType('success');
        setShowToast(true);
        appContext.visitor.photo_url = url;
    }

    const onProfileUploadFailure = (errMsg: string) => {
        setShowToast(true);
        setToastMessage('error');
        setToastMessage(errorMsg);
    }

    const submitPhoto = async () => {
        const newUserProfile = newProfile;
        setUploadFiles(newUserProfile);
        setNewProfile('');
        setShowWebCam(false);
        const file = base64ToFile(newUserProfile, 'user', 'image/jpeg');
        const response = await uploadImages(file, '/user-profile');

        dispatch(uploadProfilePicture(response, onProfileUploadSuccess, onProfileUploadFailure));
        appContext.visitor.photo_url = response;
    }

    const ModalBody = () => {
        return (
            <div className={styles.modalBodyContainer}>
                <div className={styles.modalHeader}>
                    <h3>Upload Selfie</h3>

                    <div className={styles.closeIcon} onClick={() => {
                        setShowWebCam(false);
                        setNewProfile('');
                    }}>
                        <img src={CloseIcon} alt='close-icon' width={20} height={20} />
                    </div>
                </div>
                <div className={styles.modalBodyInnerContainer}>

                    {!newProfile &&
                        <>
                            <div className={styles.camLayer}>
                                <img src={CamLayer} alt='layer' width={684} height={480} />
                            </div>
                            <Webcam
                                audio={false}
                                className={styles.webCamContainer}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                ref={webcamRef}
                                autoFocus
                            />
                        </>
                    }

                    {newProfile && <div className={styles.capturedImage}>
                        <img src={newProfile} alt='' width={640} height={480} />
                    </div>}
                </div>
                <div className={styles.modalFooter}>
                    {newProfile && <button className={styles.retakeBtn} onClick={() => setNewProfile('')}>Recapture Photo</button>}
                    <button className={newProfile ? styles.submitBtn : styles.captureBtn} onClick={newProfile ? submitPhoto : capturePhoto}>{!!newProfile ? 'Submit my picture' : 'Capture photo'}</button>
                </div>
            </div>
        )
    }

    return (
        <>
            {/* <Lightbox
                images={units?.image_url?.split(',')}
                startIndex={startIndex}
                showLightBox={showLightBox}
                setShowLightBox={setShowLightBox}
            /> */}
            {unitsLoading || loading || locationLoading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    {showToast && <Toast message={errorMsg || toastMessage} type={toastType || 'error'} />}
                    <Popup
                        open={open}
                        className="book-appointment"
                        setOpen={setIsOpen}
                        bodyData={
                            <BookAppointment
                                appointmentDate={appointmentDate}
                                appoinmentStartTime={appoinmentStartTime}
                                appointmentCloseTime={appointmentCloseTime}
                                setOpen={setIsOpen}
                                showPrice={locationDetail?.isPriceListed}
                            />
                        }
                    />
                    <div className={styles.breadcrumb}>
                        <span
                            onClick={() => {
                                navigate('/locations');
                            }}>
                            Home
                        </span>{' '}
                        {'>'}{' '}
                        <span
                            onClick={() => {
                                navigate(`/location/${locationId}`, { state: units?.locationName });
                            }}>
                            {units?.locationName}
                        </span>{' '}
                        {'>'} <span>{units?.unitYardiId ? units?.unitYardiId : units?.name}</span>
                    </div>
                    <div className={`${styles.detailsWrapper} sliderimg`}>
                        <div className={styles.sliderWrapper}>
                            {!!locationDetail && !!locationDetail?.isPriceListed && units?.price > 0 && <span className={styles.price}>${units?.price}</span>}
                            {units?.image_url?.split(',')?.length && (
                                <SliderWithLightbox
                                    imgs={units?.image_url && units?.image_url?.split(',')}
                                    setShowLightBox={setShowLightBox}
                                    showLightBox={showLightBox}
                                    startIndex={startIndex}
                                    onClickHandler={clickForLightboxHandler}
                                />
                            )}
                        </div>
                        <div className={styles.unitDetails}>
                            <div className={styles.details}>
                                <div className={styles.unitTitle}>{`${units?.unitYardiId ? units?.unitYardiId : units?.name} ${units?.nickName ? `"${units?.nickName}"` : ''
                                    } `}</div>
                                <div className={styles.facilities}>
                                    <span className={styles.facility}>
                                        <img src={iconSqfeet} alt="Square Feet" />
                                        {units?.size} Sq Ft
                                    </span>
                                    <span className={styles.facility}>
                                        <img src={iconBeds} alt="Square Feet" />
                                        {+units?.rooms === 1 ? (
                                            <span>{units?.rooms} Bed</span>
                                        ) : units?.rooms > 0 ? (
                                            <span>{units?.rooms} Beds </span>
                                        ) : (
                                            'Studio'
                                        )}
                                    </span>
                                    <span className={styles.facility}>
                                        <img src={iconBath} alt="Square Feet" />
                                        {(+units?.baths || +units?.baths === 0) && (
                                            <span>{`${+units?.baths} ${+units?.baths === 1 ? 'Bath' : 'Baths'}`}</span>
                                        )}
                                    </span>
                                    {locationDetail && !!locationDetail?.isPriceListed && <span className={styles.facility}>
                                        <img src={DollarIcon} alt="price" />
                                        {units?.price ? units?.price : '0'}*
                                    </span>}
                                </div>

                                {(units && !!units?.description) &&
                                    <div className={`${styles.description} allowSVG`}>
                                        <div className={styles.infoIconWrapper}><InfoIcon height={12} width={12} /></div>
                                        <p>{units?.description}</p>
                                    </div>}

                                <div className={styles.unitCard}>
                                    <span className={styles.priceNote}>* Final amount subject to real time availability.</span>
                                </div>

                                <div className={`${styles.btnWrapper}`}>
                                    <button onClick={openModal} className={`${styles.btnSuccess}`}>
                                        {appointmentDate && appoinmentStartTime ? 'Modify Appointment Time' : 'book an Appointment'}
                                    </button>
                                </div>

                                {/* {isDebitCard &&
                                    <div className={styles.updatePaymentDetail}>
                                        <p>Your account is currently linked to a debit card. To enhance your experience, we kindly request you to add a credit card instead.</p>
                                        <button onClick={() => navigate('/add-payment-information')} className={`${styles.btnSuccess}`} >
                                            Update Payment Method
                                        </button>
                                    </div>
                                } */}


                                {/* {
                                    !appContext?.visitor?.photo_url && <div className={styles.uploadPhotoContainer}>
                                        <p>You have not yet uploaded a profile picture; to schedule an appointment, please upload a profile picture.</p>
                                        <button className={styles.btnGreen}
                                            onClick={() => {
                                                if (hasMediaAccess) {
                                                    setShowWebCam(true)
                                                } else {
                                                    setShowToast(true);
                                                    setToastMessage('Camera access denied! Please grant access to your camera to continue.')
                                                }
                                            }}
                                        ><img src={CamIcon} alt="camera-icon" width={24} height={24} /><span> Upload Picture</span></button>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div >
            )}
            <Popup open={showWebCam} setOpen={setShowWebCam} bodyData={<ModalBody />} />
        </>
    );
};

export default UnitDetails;
