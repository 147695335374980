import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/rootReducer';
import { AmenitiesObject, AppointmentMapDetails, getAppointmentMapDetails } from '../../redux/appointment/actions/appointment.action';
import { isAccessible } from '../appointments/Appointments';
import MappedIn from './mappedIn';
import moment from 'moment';
import ChatBot from '../chatbot/ChatBot';

import Loader from '../common/loader/Loader';
import PineappleSorry from '../../images/pineapple-sorry.png';
import PineappleWaiting from '../../images/pineapple-waiting.png';
import MapNotFound from '../../images/map-not-found.svg';
import './Mappit.css';

const MappedInComponent = () => {
    const hasMounted = useRef(false);
    const dispatch: AppDispatch = useDispatch();

    const pathName = window.location.pathname.replace('/map-view/', '');

    const [appointmentDetails, setAppointmentDetails] = useState<null | AppointmentMapDetails>(null);
    const [amenitiesDetails, setAmenitiesDetails] = useState<null | AmenitiesObject[]>(null)
    const [statusCode, setStatusCode] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const onSuccess = (appointment: AppointmentMapDetails, amenity: AmenitiesObject[] | null) => {
        setIsLoading(false);
        setStatusCode('200');
        setAppointmentDetails(appointment);
        if (amenity) {
            setAmenitiesDetails(amenity);
        }
    };

    const onFailure = (message: string, statusCode: string) => {
        setIsLoading(false);
        setStatusCode(statusCode);
        setMessage(message);
    };

    useEffect(() => {
        if (pathName && pathName.trim().length && hasMounted) {
            setIsLoading(true);
            dispatch(getAppointmentMapDetails(pathName, onSuccess, onFailure));
        }
    }, [pathName]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            const appTag = document.getElementsByClassName('App');
            if (appTag) {
                appTag[0].classList.add('mappedin-app')
            }


            return () => {
                if (appTag) {
                    appTag[0].classList.remove('mappedin-app')
                }
            }
        }
    }, []);

    const applyAppLink = async () => {
        const applicationLink = appointmentDetails?.appLink;
        if (applicationLink) {
            window.open(applicationLink, '_blank', 'noreferrer');
            await axios.get(`appointments/ReadyToApplyClicked/${appointmentDetails.appointmentId}`);
        }
    };

    const extractMapId = (mapId: string) => {
        const pattern = /^https:\/\/app\.mappedin\.com\/map\/([^?/]+)(?:\?floor=.*)?$/;
        const match = mapId.match(pattern);
        return match ? match[1] : mapId;
    };

    const extractFloorId = (mapId: string) => {
        const pattern = /https:\/\/app\.mappedin\.com\/map\/([^?/]+)\?floor=([^?/]+)/;
        const match = mapId.match(pattern);
        return match ? match[2] : null;
    }

    return (
        <div className="map-view-container">
            {isLoading && (
                <div className="custom-loader">
                    <Loader />
                </div>
            )}

            {statusCode.toString() === '404' && (
                <div className={'error-container'}>
                    <img className={'notFoundImage'} src={PineappleSorry} alt="404" />
                    <p>{message}</p>
                    <a className={'btnGreen'} href="/">
                        Back to appointments
                    </a>
                </div>
            )}

            {statusCode.toString() === '200' &&
                appointmentDetails &&
                !appointmentDetails.mappedInId &&
                !appointmentDetails.mapFilePath &&
                isAccessible(
                    moment(appointmentDetails?.reservedDate).utc().format('MM/DD/YYYY'),
                    appointmentDetails?.start_time,
                    appointmentDetails?.end_time,
                ) === 1 && (
                    <div className={'error-container'}>
                        <img className={'mapNotFound'} src={MapNotFound} alt="404" width={718} />
                        <p>{'Map view not found.'}</p>
                        <a className={'btnGreen'} href="/">
                            Back to appointments
                        </a>
                    </div>
                )}

            {!!pathName && appointmentDetails && isAccessible(moment(appointmentDetails?.reservedDate).utc().format('MM/DD/YYYY'), appointmentDetails?.start_time, appointmentDetails?.end_time) !== 1 && <div className={'error-container'}>
                <img className={'notFoundImage'} src={isAccessible(moment(appointmentDetails?.reservedDate).utc().format('MM/DD/YYYY'), appointmentDetails?.start_time, appointmentDetails?.end_time) === 0 ? PineappleWaiting : PineappleSorry} alt="waiting" />
                <p>{isAccessible(moment(appointmentDetails?.reservedDate).format('MM/DD/YYYY'), appointmentDetails?.start_time, appointmentDetails?.end_time) === 0 ? "Your tour hasn't started yet." : "This tour is no longer available."}</p>
                <a className={'btnGreen'} href='/'>
                    Back to appointments
                </a>
            </div>
            }

            {!!pathName && appointmentDetails && appointmentDetails.openAITourVectorId && appointmentDetails.openAITourFileId && (
                <ChatBot
                    appointmentId={pathName}
                    className={
                        appointmentDetails && !appointmentDetails.mappedInId
                            ? 'engrain-map'
                            : !appointmentDetails.mappedInId && !appointmentDetails.mapFilePath
                                ? 'no-map-view'
                                : ''
                    }
                />
            )}

            {statusCode === '200' && appointmentDetails && isAccessible(moment(appointmentDetails?.reservedDate).utc().format('MM/DD/YYYY'), appointmentDetails?.start_time, appointmentDetails?.end_time) === 1 && appointmentDetails?.mappedInId &&
                <MappedIn
                    mapId={extractMapId(appointmentDetails.mappedInId)}
                    floorId={appointmentDetails.mappedInFloorId ? appointmentDetails.mappedInFloorId : extractFloorId(appointmentDetails.mappedInId)}
                    agentNumber={appointmentDetails?.leasingAgentNumber || null}
                    rentalLink={appointmentDetails?.appLink || null}
                    amenitiesData={amenitiesDetails}
                    appointmentId={pathName || ''}
                />
            }


            {statusCode === '200' &&
                appointmentDetails &&
                isAccessible(
                    moment(appointmentDetails?.reservedDate).utc().format('MM/DD/YYYY'),
                    appointmentDetails?.start_time,
                    appointmentDetails?.end_time,
                ) === 1 &&
                appointmentDetails?.mapFilePath &&
                !appointmentDetails?.mappedInId &&
                (
                    <iframe
                        src={appointmentDetails?.mapFilePath as string}
                        title="Mapped In"
                        height="auto"
                        style={{ border: 'none', objectFit: 'cover', maxWidth: '100%' }}
                        id="mappedin-map"
                        className="mappedin-map"
                    />
                )}

            {statusCode === '200' && appointmentDetails && !appointmentDetails.mappedInId && (
                <div className={`bottom-btn-container `}>
                    {appointmentDetails && appointmentDetails.leasingAgentNumber && (
                        <a className="map-callbtn" href={`tel:+1${appointmentDetails?.leasingAgentNumber}`}>
                            Call Agent
                        </a>
                    )}
                    {appointmentDetails && (
                        <div className={`apply-btn`} onClick={applyAppLink}>
                            Apply now
                        </div>
                    )}
                </div>
            )}

        </div>
    );
};

export default MappedInComponent;
