import React, { useEffect, useRef, useState } from 'react';
import style from './chatbot.module.css';
import CloseIcon from '../../images/transparent-close.svg';
import { useForm, Controller } from "react-hook-form";
import UpArrowIcon from '../icons/UpArrowIcon';
import { AppDispatch } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { askToChatBot, getChatHistory } from '../../redux/chatbot/chatbot.action';
import Lady from '../../images/lady.jpeg';
import ChatBackground from '../../images/chat-background.png';
import Torrie from '../../images/torrie-chatbot.svg';
import TorrieHeader from '../../images/torrie-header.svg';
import TorrieBot from '../../images/torrie.svg';
import SendIcon from '../icons/SendIcon';

export interface ChatHistory {
    id: number;
    question: string;
    answer: string | null;
}

interface ChatBotProps {
    appointmentId: string;
    getKeyWords?: (query: string) => void;
    className?: string;
}

interface OldChatHistory {
    q: string;
    a: string;
}

const ChatBot = ({ appointmentId, getKeyWords, className }: ChatBotProps) => {
    const [locationName, setLocationName] = useState<null | string>(null);

    const divRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const [expandChatBot, setExpandChatBot] = useState(false);
    const [chatHistory, setChatHistory] = useState<ChatHistory[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const {
        getValues,
        reset,
        formState: { isValid },
        control,
    } = useForm({
        defaultValues: {
            query: '',
        },
        mode: 'onChange',
    });

    const onSuccess = (response: string) => {
        setIsLoading(false);
        setChatHistory((prevChatHistory) => {
            if (!prevChatHistory) return null;
            const updatedHistory = [...prevChatHistory];
            updatedHistory[updatedHistory.length - 1].answer = response;
            return updatedHistory;
        });
    };

    const onFailure = (errorMessage: string) => {
        setIsLoading(false);
        setChatHistory(null);
    };

    const onAskToChatBot = () => {
        setIsLoading(true);

        const query = getValues('query');

        reset();
        const prevId = (chatHistory?.length && chatHistory[chatHistory?.length - 1]?.id) || 0;

        if (chatHistory) {
            setChatHistory([...chatHistory, { id: prevId + 1, question: query, answer: null }]);
        } else {
            setChatHistory([{ id: prevId + 1, question: query, answer: null }]);
        }
        dispatch(askToChatBot(query, appointmentId, onSuccess, onFailure));
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const restoreChatHistory = (chat: OldChatHistory[]) => {
        let prevId = (chatHistory?.length && chatHistory[chatHistory?.length - 1]?.id) || 0;
        let oldHistory: ChatHistory[] = [];
        chat?.length &&
            chat?.forEach((thread: OldChatHistory, index: number) => {
                oldHistory.push({ id: prevId, answer: thread?.a, question: thread?.q });
                prevId++;
            });
        setChatHistory(oldHistory);
    };

    const onHistorySuccess = (chat: any, name: string) => {
        if (name) {
            setLocationName(name);
        }
        if (chat?.length) {
            restoreChatHistory(chat);
        } else {
            setChatHistory(null);
        }
        setIsLoading(false);
    };

    const onHistoryFailure = () => {
        setIsLoading(false);
        setChatHistory(null);
    };

    useEffect(() => {
        dispatch(getChatHistory(appointmentId, onHistorySuccess, onHistoryFailure));
        scrollToBottom();

        return () => {
            setIsLoading(true);
            setChatHistory(null);
        };
    }, []);

    return (
        <div className={`${style.chatBotContainer} chatBotContainer ${className ? className : ''}`}>
            <div className={`${style.chatBotRootContainer} ${!expandChatBot ? style.chatBotSmallContainer : ''}`}>
                <div className={style.container}>
                    <div className={style.chatBotHeader}
                        onClick={() => {
                            if (!expandChatBot) {
                                setExpandChatBot(true);
                            }
                        }}>
                        <div className={style.botLeftContainer}>
                            <div className={style.chatAvatar}>
                                <img src={expandChatBot ? TorrieHeader : Torrie} alt='torrie' width={expandChatBot ? 56 : 32} height={expandChatBot ? 63 : 36} />
                            </div>

                            {expandChatBot && <div className={style.botInformation}>
                                <span className={style.botName}>Torrie</span>
                                <span className={style.botInfo}>We replies in few minutes</span>
                            </div>}
                        </div>

                        <div className={style.headerDotsWrapper}>
                            <span className={style.dot}></span>
                            <span className={style.dot}></span>
                            <span className={style.dot}></span>
                        </div>
                        {expandChatBot &&
                            <div className={style.chatClose} onClick={() => setExpandChatBot(false)}>
                                <img src={CloseIcon} alt='close icon' width={24} height={24} />
                            </div>
                        }
                    </div>

                    <div className={style.chatBotBody} >
                        {
                            !chatHistory && <div className={style.bot}>
                                <div className={style.botProfile}>
                                    <div className={style.botImage}>
                                        <img src={TorrieBot} width={41} height={41} alt='torrie-bot' />
                                    </div>
                                </div >
                                <div className={style.systemMessage}>{`Hello There!\nHow can I help you today?`}
                                </div>
                            </div >
                        }

                        {
                            !!chatHistory?.length && chatHistory?.map((chat: ChatHistory, index: number) => {
                                return (<div className={style.chatContainer}>

                                    <div className={style.userQuery}>{chat?.question}</div>

                                    <div className={style.chatResponseContainer}>
                                        <div className={style.botProfile}>
                                            <img src={TorrieBot} width={41} height={41} alt='torrie' />
                                        </div>
                                        {!chat?.answer && isLoading ? <div className={style.dotsWrapper}>
                                            <span className={style.dot}></span>
                                            <span className={style.dot}></span>
                                            <span className={style.dot}></span>
                                        </div> : <div className={style.chatBotAnswer}>{chat?.answer}</div>}
                                    </div>
                                </div>)
                            })
                        }

                        <div ref={divRef}></div>
                    </div >

                    <div className={style.chatBotFooter}>
                        <div className={style.inputHolder}>
                            <form>
                                <Controller
                                    name="query"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => {
                                        return (<div className={style.inputWrapper}>
                                            <input
                                                value={value}
                                                onChange={onChange}
                                                id="query"
                                                name="query"
                                                placeholder="Type and press enter"
                                            />
                                        </div>)
                                    }}
                                />

                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onAskToChatBot()
                                    }} disabled={!isValid || isLoading || getValues('query').trim() === ''}
                                    type="submit" >
                                    <SendIcon fill={(!isValid || isLoading || getValues('query').trim() === '') ? '#d1d2d3' : '#fff'} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatBot;
