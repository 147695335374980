import React from 'react';

interface SendIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const SendIcon = ({ width = 16, height = 16, fill = "#D1D2D3" }: SendIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1963_23350)">
        <path
          d="M14.7335 7.04139L1.83461 0.749232C1.72368 0.695119 1.60186 0.666992 1.47843 0.666992C1.02975 0.666992 0.666016 1.03073 0.666016 1.47941V1.50285C0.666016 1.61187 0.679382 1.72047 0.705822 1.82623L1.94314 6.77546C1.97693 6.91066 2.09123 7.01053 2.22971 7.02593L7.66808 7.63019C7.85668 7.65113 7.99935 7.81053 7.99935 8.00033C7.99935 8.19013 7.85668 8.34953 7.66808 8.37046L2.22971 8.97473C2.09123 8.99013 1.97693 9.08999 1.94314 9.22519L0.705822 14.1744C0.679382 14.2802 0.666016 14.3888 0.666016 14.4978V14.5213C0.666016 14.9699 1.02975 15.3337 1.47843 15.3337C1.60186 15.3337 1.72368 15.3055 1.83461 15.2514L14.7335 8.95926C15.1001 8.78046 15.3327 8.40826 15.3327 8.00033C15.3327 7.59239 15.1001 7.22019 14.7335 7.04139Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1963_23350">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendIcon;
