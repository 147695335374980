import { useState, useEffect, useCallback } from "react";

interface UseCameraAccessResult {
  isEnabled: boolean;
  isSupported: boolean;
  stream: MediaStream | null;
  error: string | null;
  enableCamera: () => Promise<boolean>;
  disableCamera: () => void;
}

export const useCameraAccess = (): UseCameraAccessResult => {
  const isSupported = !!navigator.mediaDevices?.getUserMedia;
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);

  const enableCamera = useCallback(async (): Promise<boolean> => {
    if (!isSupported) {
      setError("Camera access is not supported in this browser.");
      return false;
    }

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);
      setError(null);
      return true; // Success
    } catch (err) {
      setError((err as Error).message);
      return false; // Failure
    }
  }, [isSupported]);

  const disableCamera = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  }, [stream]);

  useEffect(() => {
    return () => {
      disableCamera(); // Cleanup on unmount
    };
  }, [disableCamera]);

  return { isEnabled: !!stream, isSupported, stream, error, enableCamera, disableCamera };
};
