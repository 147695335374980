import React from 'react'
import { AppointmentsProps } from '../appointments.interface';
import Style from './AppointmentCard.module.css';
import moment from 'moment';
import MapIcon from '../../icons/MapIcon';
import LockIcon from '../../icons/LockIcon';
import ThreeDotsIcon from '../../icons/ThreeDots';
import { useNavigate } from 'react-router-dom';
import { isAccessible } from '../Appointments';
import OpenViaIcon from '../../icons/OpenViaIcon';
import SwiperButton from '../../SwiperButton/SwiperButton';

interface AppointmentCardProps {
  appointment: AppointmentsProps;
  background?: string;
  onMenuClick?: () => void;
  onAmazonDoor?: () => void;
  loading?: boolean
}

const AppointmentCard = ({ appointment, background, onMenuClick, onAmazonDoor, loading }: AppointmentCardProps) => {

  const navigate = useNavigate();
  const appointmentStatus = isAccessible(appointment?.appointmentDate, appointment?.appointmentStartTime, appointment?.appointmentEndTime);

  return (
    <div className={`${Style.appointmentCard} appointmentCard`} style={{ backgroundColor: background ? background : '#F6F6F6' }}>
      <div className={Style.upperContainer}>

        <div className={Style.iconWrapper} onClick={onMenuClick && onMenuClick}>
          <ThreeDotsIcon />
        </div>

        <div className={Style.detailWrapper}>
          <p className={Style.title}>Location name</p>
          <a className={Style.info} href={`/location/${appointment?.locationId}`} target='_blank'>{appointment.locationName}</a>
        </div>

        <div className={Style.detailWrapper}>
          <p className={Style.title}>Unit name</p>
          <p className={Style.info}>{appointment.unitYardiId ? appointment.unitYardiId : appointment.unitName}</p>
        </div>

        <div className={Style.inlineItems}>
          <div className={Style.detailWrapper}>
            <p className={Style.title}>Date</p>
            <p className={Style.info}>{moment(appointment.appointmentDate).format('MM-DD-YYYY')}</p>
          </div>

          <div className={Style.detailWrapper}>
            <p className={Style.title}>Time</p>
            <p className={Style.info}>{moment(appointment.appointmentStartTime, 'hh:mm A').format('h:mm A')} -{' '}
              {moment(appointment.appointmentEndTime, 'hh:mm A').format('h:mm A')}</p>
          </div>
        </div>

        {/* {!appointment?.amazonKeyAccessId && appointment?.openViaSlug && <div className={`${Style.openViaContainer} ${appointment.openViaSlug === null ? Style.disabled : ''}`}>
          <span>Open Via</span>
          <OpenViaIcon fill={appointment.openViaSlug === null ? '#D1D2D3' : '#3674EE'} width={24} height={24} />
        </div>} */}

        {(appointment.amazonKeyAccessId !== null && appointmentStatus === 1) && <div>
          <SwiperButton
            loading={loading}
            onDragend={() =>
              onAmazonDoor?.()
            } />
        </div>
        }

      </div>

      {<div className={`${Style.lowerContainer} ${((appointment.amazonKeyAccessId && appointment?.amazonKeyAccessCount > 0) || !appointment.amazonKeyAccessId) ? Style.show : ''}`}>
        <div className={Style.infoBox}>
          <div className={Style.leftContainer}>
            <LockIcon />
          </div>
          <div className={Style.rightContainer}>
            <p>Kiosk Code</p>
            <p>{appointment?.accessCode}</p>
          </div>
        </div>

        <div className={Style.infoBox}>
          <div className={Style.leftContainer}>
            <MapIcon stroke='#fff' />
          </div>
          <div className={Style.rightContainer}>
            <p>I want to</p>
            <p onClick={() => navigate(`/map-view/${appointment?.appointmentId}`)}>Navigate my tour.</p>
          </div>
        </div>
      </div>}


      {/* <div className={Style.middleContainer}>
        <button className={Style.actionBtn}>
          <MapIcon />
          <span className={Style.btnText}>View In Map</span>
        </button>

        <button className={Style.actionBtn}>
          <EditIcon />
          <span className={Style.btnText}>Edit</span>
        </button>

        <button className={Style.actionBtn}>
          <DeleteIcon />
          <span className={Style.btnText}>Delete</span>
        </button>
      </div> */}
    </div >
  )
}

export default AppointmentCard